import React from 'react';
import { ButtonWithImage } from './ButtonWithImage'; // Adjust the path as needed
import './ButtonGroup.css';

interface IButtonGroup {
    theme: 'light' | 'dark';
}


const ButtonGroup = (props: IButtonGroup) => {
    const buttons = [
        { imageSrc: 'Images/usa.png', text: 'English', lang: 'en' },
        { imageSrc: 'Images/russia.png', text: 'Русский', lang: 'ru' },
        { imageSrc: 'Images/israel.png', text: 'עברית', lang: 'he' },
        // { imageSrc: 'Images/spain.png', text: 'spain' },
        // { imageSrc: 'Images/france.png', text: 'france' },
    ];

    return (
        <div className={`button-group ${props.theme}`}>
            {buttons.map((button, index) => (
                <ButtonWithImage
                    key={index}
                    imageSrc={button.imageSrc}
                    text={button.text}
                    lang={button.lang}
                />
            ))}
        </div>
    );
};

export default ButtonGroup;
