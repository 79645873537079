import React, { useState } from 'react';
import './Numpad.css';

interface INumpad {
    theme: 'light' | 'dark';
    onNumberChanged: (num: string) => void;
}

export const Numpad = (props: INumpad) => {

    const numLength = 8;
    const [input, setInput] = useState<string>('');

    const handleButtonClick = (value: string) => {
        if (input.length < numLength) {
            updateInput(input + value);
        }
    };

    const handleDelete = () => {
        updateInput(input.slice(0, -1));
    };

    const handleClear = () => {
        updateInput('');
    };
    const updateInput = (str: string) => {
        setInput(str);
        props.onNumberChanged(str);
    };

    return (
        <div className={`numpad-container ${props.theme}`}>
            <div className="digit-display">
                {[...input.padEnd(numLength, ' ')].map((digit, index) => (
                // {[...input.padStart(numLength, ' ')].map((digit, index) => (
                    <img
                        key={index}
                        src={digit === ' ' ? '/images/' + props.theme + '/blank.png' : '/images/' + props.theme + '/num/' + digit + '.png'}
                        alt={digit}
                        className="digit-image"
                    />
                ))}
            </div>
            <div className="buttons-container">
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', 'C', '0', 'D'].map((value) => (

                    <input className="input-img"  key={value} type="image" src={`/images/${props.theme}/${value}.png`} alt={value} onClick={() => {
                        if (value === 'C') handleClear();
                        else if (value === 'D') handleDelete();
                        else handleButtonClick(value);
                    }}
                    />
                ))}
            </div>
        </div>
    );
};

