import React from 'react';
import i18n from 'i18next';
import './ButtonWithImage.css';

interface IButtonWithImage {
  imageSrc: string;
  text: string;
  lang: string;
}


export const ButtonWithImage = (props: IButtonWithImage) => {
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng); // Change language dynamically
  };

  return (
    <div className="button-container" onClick={() => changeLanguage(props.lang)}>
      <img src={props.imageSrc} alt={props.text} className="button-image"  />
      <span className="button-text">{props.text}</span>
    </div>
  );
};