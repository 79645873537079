import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../locales/en/translation.json';
import heTranslation from '../locales/he/translation.json';
import ruTranslation from '../locales/ru/translation.json';

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      ru: {
        translation: ruTranslation,
      },
      he: {
        translation: heTranslation,
      },
    },
    lng: 'he', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already escapes output
    },
  });

export default i18n;
