import React, { useState } from 'react';
import { Numpad } from './components/Numpad';
import './App.css';
import ButtonGroup from './components/ButtonGroup';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const { t, i18n } = useTranslation(); // Ensure this hook is correctly used

  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const [input, setInput] = useState<string>('');

  const toggleTheme = () => {
    setTheme((prev) => (prev === 'light' ? 'dark' : 'light'));
  };

  return (
    <div className={`app-container ${theme}`}>
      <ButtonGroup theme={theme} />
      {/* <span className="app-title">{input}</span> */}
      {/* <span className='package-number'>{t('enterPackageNumber')}</span> */}
      <Numpad
        theme={theme}
        onNumberChanged={(num) => {
          setInput(num);
        }}
      />
      <span className='package-number'>{t('enterPackageNumber')}</span>

      <button className="get-package-button" onClick={() => { }}>{t('getPackageButton')}</button>
      <input className="dis-toggle-button" type="image" src={`/images/${theme}/dis.png`} alt={""} onClick={toggleTheme} />
    </div>
  );
};

export default App;

// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
